import React from 'react';
import { Router } from '@reach/router';
import Dashboard from '../components/Dashboard';
import PrivateRoute from '../components/PrivateRoute';

const Index = () => {

  return (
    
      <Router basepath="/dashboard">
        <PrivateRoute path="/" component={Dashboard} />
      </Router>
  )
};

export default Index;