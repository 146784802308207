import React, { useEffect } from 'react';
import { useAuthenticationStatus } from '../hooks';


const PrivateRoute = ({ component: Component, location, ...rest }: any) => {

  const [isAuthenticated, loading] = useAuthenticationStatus();
  useEffect(() => {
    if (loading) {
      return;
    }
    if (!isAuthenticated) {
      if (typeof window !== 'undefined') {
        // window.location.href = 
        //   process.env.NODE_ENV === 'development'
        //     ? `http://localhost:3001/`
        //     : 'https://login.listwithclever.com';
      }
    }
  }, [isAuthenticated, loading])

  return <Component {...rest} />

};

export default PrivateRoute;
