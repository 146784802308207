import React from 'react';
import './MessageCard.scss';

const MessageCard = ({
  message,
}: any) => {

  return (
    <div className={`messageCard ${!message.messages[0].read && 'unread'}`}>
      <div className="column twoThirds">
        <div className="rowItem">
          <h4>{message.name}</h4>
          <p className="darkCopy"><strong>{message.messages[0].date}</strong></p>
        </div>
        <div className="rowItem">
          <p className="cutOff">{message.messages[0].message}</p>
        </div>
      </div>
      <div className="column end oneThird">
        <div className="rowItem end">
          {/* message number */}
          {!message.messages[0].read && <div className="messageNum">1</div>}
          {/* selected icon */}
        </div>
      </div>
      
    </div>
  )
}

export default MessageCard;
