import React, { useState } from 'react';
import MessageCard from '../Cards/MessageCard';
import SearchField from '../Fields/SearchField';
import Layout from '../layout';
import './index.scss';

const EmailIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 2H0.5L7 7.354L13.5735 2.015C13.5494 2.00797 13.5249 2.00296 13.5 2Z" fill="white"/>
    <path d="M7.31548 8.38805C7.13126 8.5389 6.86622 8.5389 6.682 8.38805L0 2.88306V11.5001C0 11.7762 0.223857 12.0001 0.500009 12.0001H13.5C13.7761 12.0001 14 11.7762 14 11.5001V2.95706L7.31548 8.38805Z" fill="white"/>
  </svg>
);

const sampleMessages = [
  {
    messages: [
      {
        message: 'Hey Jontray, do you remember that time when we were gunna talk about Clever real estate and how awesome it is',
        date: '05/03/21 11:26am'
      }
    ],
    read: false,
    name: 'Jessica Koel',
  }
]

const Dashboard = () => {
  const [search, setSearch] = useState('');

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      // TODO: implement search
      // handle search
    }
  }

  return (
    <Layout>
      <div className="dashboardMiddleContainer">
        <div className="dashboardNavigation">
          <div className="dashboardNav">
            <button className="backButton" />
            <h2>Communications</h2>
          </div>
          <div className="communicationOptions">
            <button className="sendMessage"><EmailIcon /> New Message</button>
            <div className="dropdownOption">
              <div className="optionWithIcon">
                <div className="dropArrow up" />
                {/* Insert arrow  */}
                {/* icon */}
                Inbox (Coming soon)
              </div>
              {/* Notifications */}
            </div>
            <div className="addFeature">
              <p>Team Box</p>
              <button className="addButton" />
            </div>
            <div className="dropdownOption">
              <div className="optionWithIcon">
                <div className="dropArrow up" />
                {/* Insert arrow */}
                {/* Icon */}
                Team Inboxes
              </div>
                {/* Notifications */}
            </div>
          </div>
        </div>
        <div className="dashboardMessageContainer">
          <div className="dashboardMessageUtilityContainer">
            <SearchField
              onChange={(e: any) => setSearch(e.target.value)}
              value={search}
              onKeyDown={handleKeyDown}
              placeholder="Search"
            />
            {/* utility buttons */}
          </div>
          <div className="dashboardMessages">
            {/* Add messages here */}
            {sampleMessages.map((message, index) => (
              <MessageCard key={`message-${index}`} message={message} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Dashboard;
