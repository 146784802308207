import React from 'react';
import './SearchField.scss';

const SearchField = (
  props: any,
) => {

  return (
    <div className="searchInputContainer">
      <input className="searchInputField" {...props} />
    </div>
  )
}

export default SearchField;